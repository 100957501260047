import request from '../request'
export default {
  // 获取项目
  getProjectList (params) {
    return request({
      url: '/api/getProjectList',
      method: 'get',
      params
    })
  },

  // 获取项目详情
  getProjectDetail (params) {
    return request({
      url: '/api/getProjectDetail',
      method: 'get',
      params
    })
  },

  // 项目Rise
  getProjectRise (params) {
    return request({
      url: '/api/getProjectRise',
      method: 'get',
      params
    })
  },
  
  // 项目配置
  getProjectConfig (params) {
    return request({
      url: '/api/getProjectConfig',
      method: 'get',
      params
    })
  },

  // 新增或修改项目
  addOrEditProject (data) {
    return request({
      url: '/api/addOrEditProject',
      method: 'POST',
      data
    })
  },

  // 上传文件
  uploadFileProject (data) {
    return request({
      url: '/api/uploadFileProject',
      method: 'POST',
      data
    })
  }

}
